import '../css/styles.scss'
import Site from './lib/Site.js'
import ListFilters from './lib/ListFilters.js'
import Forms from './lib/Forms.js'

if (document.body.id !== 'form-builder-form') {
	window._site = new Site()
	window._listFilters = new ListFilters()
	window._forms = new Forms()
}
