'use strict'

import Swal from 'sweetalert2'

export default class Forms {

	/**
	 * Class constructor
	 */
	constructor () {
		this.aggregateFormData = {}
		this.init()
	}

	/**
	 * Initialize form behaviour
	 *
	 * @return {Object}
	 */
	init () {

		// initialize individual form features
		this.initDoubleSubmissionPrevention()
		this.initConfirmsubmission()
		this.initFormToggles()
		this.initQuickAdd()
		this.initFormFieldLimits()
		this.initFormFieldIfRules()
		this.initFormFieldOnSelect()
		this.initFormFieldUnique()
		this.initFormFieldAnswered()
		this.initCloneNode()
		this.initDeleteNode()
		this.initPopulateForm()
		this.initFormBuilderForm()
		this.initAggregateForm()
		this.initStandAloneFormBuilderForms()

		// store initial values for form fields
		const jsClasses = [
			['.js-form-field-limited', 'limited'],
			// note: "if" disabled intentionally, roles were not disabling properly in registration events view
			// ['.js-form-field-if', 'if'],
			['.js-populate-form', 'populate'],
		]
		jsClasses.forEach(jsClass => {
			document.querySelectorAll(jsClass[0]).forEach(formField => {
				formField.setAttribute('data-prev-value--' + [jsClass[1]], formField.type === 'radio' && !formField.checked ? '' : formField.value)
				formField.setAttribute('data-is-initial--' + [jsClass[1]], true)
			})
		})

		// trigger field change listeners
		this.triggerFieldChangeListeners()
	}

	initDoubleSubmissionPrevention () {

		// prevent double submissions
		document.addEventListener('submit', event => {
			const form = event.target
			if (form.classList.contains('js-form-in-progress')) {
				event.preventDefault()
				return
			}
			form.classList.add('js-form-in-progress')
		})
	}

	initConfirmsubmission () {

		// confirm forms
		document.addEventListener('submit', event => {
			this.confirmSubmit(event)
		})

		// confirm buttons
		document.addEventListener('click', event => {
			this.confirmSubmit(event)
		})
	}

	initFormToggles () {

		// form open and close buttons
		document.addEventListener('click', event => {
			const button = event.target.closest('.js-form-button')
			if (!button || button.disabled) return
			event.preventDefault()
			if (button.classList.contains('js-form-open')) {
				const formName = button.getAttribute('data-form')
				const formTemplate = document.querySelector('[data-form-template="' + formName + '"]')
				if (formTemplate) {
					this.hideFormOpenButtons()
					const formContainer = formTemplate.cloneNode(true)
					const formContext = button.closest('.js-form-context--form-open') || button.closest('.js-form-context') || document
					const formPlaceholder = formContext.querySelector('.js-form-placeholder[data-form="' + formName + '"]')
					formPlaceholder.appendChild(formContainer)
					formPlaceholder.removeAttribute('hidden')
					let formData = button.getAttribute('data-form-data')
					if (formData) {
						formContainer.querySelectorAll('[name]').forEach(formField => {
							if (formField.type === 'hidden') return
							formField.value = ''
						})
						formData = JSON.parse(formData)
						for (let fieldName in formData) {
							if (Object.prototype.hasOwnProperty.call(formData, fieldName) && formData[fieldName]) {
								const formField = formContainer.querySelector('[name="' + fieldName + '"]')
								if (formField) {
									formField.value = formData[fieldName]
									const formFieldLabel = formField.closest('label')
									if (formFieldLabel && formFieldLabel.getAttribute('data-editable') === 'false') {
										formField.setAttribute('disabled', '')
										const hiddenFormField = document.createElement('input')
										hiddenFormField.setAttribute('type', 'hidden')
										hiddenFormField.setAttribute('name', formField.name)
										hiddenFormField.setAttribute('value', formField.value)
										formField.parentNode.appendChild(hiddenFormField)
										const formFieldInstructions = formFieldLabel.querySelector('.js-form-field-instructions')
										if (formFieldInstructions) {
											formFieldInstructions.remove()
										}
									}
								}
							}
						}
					}
					formContainer.removeAttribute('data-form-template')
					formContainer.removeAttribute('hidden')
					formContainer.querySelector('input:not([type="hidden"]').focus()
					window._site.scrollTo(formPlaceholder)
				}
			} else if (button.classList.contains('js-form-close')) {
				const formContainer = button.closest('.js-form-container')
				if (formContainer) {
					const formPlaceholder = formContainer.closest('.js-form-placeholder')
					if (formPlaceholder) {
						formPlaceholder.setAttribute('hidden', '')
					}
					formContainer.remove()
					this.restoreFormButtons()
				}
			}
		})

		// look for forms that should be opened automatically
		const openForms = document.querySelectorAll('[data-form-open]')
		if (openForms.length) {
			openForms.forEach(openForm => {

				// open any accordion sections enclosing this form
				let accordion = openForm.closest('.js-accordion-section')
				while (accordion) {
					document.querySelector('[aria-controls="' + accordion.id + '"]').click()
					accordion = accordion.parentElement.closest('.js-accordion-section')
				}

				// hide/disable form open buttons
				this.hideFormOpenButtons()

				// display form
				openForm.click()

				// check if there are errors in the placeholder for this form and remove them
				const formTemplate = document.querySelector('[data-form-template="' + openForm.getAttribute('data-form') + '"]')
				if (formTemplate) {
					const formMessage = formTemplate.querySelector('.js-message')
					if (formMessage) {
						formMessage.remove()
					}
				}
			})
		}
	}

	initQuickAdd () {

		document.addEventListener('change', event => {
			if (!event.target.classList.contains('js-quick-add')) return
			if (event.target.value !== 'new') return
			const context = event.target.closest('.js-quick-add-context')
			context.querySelectorAll('.js-quick-add-hide').forEach(item => {
				item.classList.add('js-hide')
			})
			context.querySelectorAll('.js-quick-add-show').forEach(item => {
				item.classList.remove('js-hide')
			})
			context.classList.add('js-quick-add-context--active')
			context.querySelector('.js-quick-add-input[data-quick-add-focus]').focus()
		})

		document.addEventListener('click', event => {
			const button = event.target.closest('.js-quick-add-save')
			if (!button || button.disabled) return
			event.preventDefault()
			const context = button.closest('.js-quick-add-context')
			const formData = new FormData()
			let isError = false
			context.querySelectorAll('.js-quick-add-input').forEach(input => {
				if (isError) return
				formData.append(input.name, input.value)
				if (!input.checkValidity()) {
					input.focus()
					input.reportValidity()
					isError = true
				}
			})
			context.querySelectorAll('.js-quick-add-extra').forEach(extra => {
				if (!extra.value) return
				formData.append(extra.name, extra.value)
				if (!extra.checkValidity()) {
					extra.focus()
					extra.reportValidity()
					isError = true
				}
			})
			if (!isError) {
				formData.append('action', context.getAttribute('data-quick-add-action'))
				formData.append('quick_add_type', context.getAttribute('data-quick-add-type'))
				formData.append(context.getAttribute('data-quick-add-token'), context.getAttribute('data-quick-add-value'))
				button.classList.add('button--loading')
				context.querySelectorAll('button').forEach(buttonInContext => {
					if (buttonInContext.disabled) return
					buttonInContext.disabled = true
					buttonInContext.classList.add('js-quick-add-button-disabled')
					buttonInContext.classList.add('button--disabled')
				})
				fetch(context.getAttribute('data-quick-add-target'), {
					method: 'POST',
					body: formData,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				})
					.then(response => response.json())
					.then(data => {
						if (data.error) {
							const errorTitle = context.getAttribute('data-quick-add-error-title')
							Swal.fire({
								html: '<div class="swal2-html-inner">'
									+ (errorTitle ? '<h2>' + errorTitle + '</h2>' : '')
									+ '<p>' + data.error + '</p>'
									+ '</div>',
								confirmButtonColor: '#FCE5EF',
							})
						} else {
							context.querySelectorAll('.js-quick-add-show').forEach(item => {
								item.classList.add('js-hide')
							})
							context.querySelectorAll('.js-quick-add-hide').forEach(item => {
								item.classList.remove('js-hide')
							})
							const input = context.querySelector('.js-quick-add')
							if (input) {
								document.querySelectorAll('select[name="' + input.name + '"]').forEach(select => {
									const option = document.createElement('option')
									option.value = data.value
									option.innerText = data.label
									option.setAttribute('data-if--role_id', data.roles)
									const newOption = select.querySelector('[value="new"]')
									if (newOption) {
										select.insertBefore(option, newOption)
										return
									}
									select.appendChild(option)
								})
								input.value = data.value
								context.querySelectorAll('.js-form-field-if').forEach(input => {
									// new option added so if-rules need to be re-checked, reset previous value
									input.removeAttribute('data-prev-value--if')
								})
								this.triggerFieldChangeListeners()
							}
							context.querySelectorAll('.js-quick-add-input').forEach(input => {
								if (input.hidden || input.type === 'hidden') return
								input.value = ''
							})
							const aggregateForm = document.getElementById('js-aggregate-form')
							if (aggregateForm) {
								this.aggregateFormData = JSON.stringify(this.processAggregateForm(aggregateForm))
							}
						}
						button.classList.remove('button--loading')
						context.classList.remove('js-quick-add-context--active')
						context.querySelectorAll('.js-quick-add-button-disabled').forEach(buttonInContext => {
							buttonInContext.disabled = false
							buttonInContext.classList.remove('js-quick-add-button-disabled')
							buttonInContext.classList.remove('button--disabled')
						})
					})
			}
		})
	}

	initFormFieldLimits () {

		// enforce limits for fields (within form context)
		document.addEventListener('change', event => {

			const input = event.target
			if (!input.classList.contains('js-form-field-limited')) return

			const formContext = input.closest('.js-form-context--limited') || input.closest('.js-form-context') || document
			const bagOfValues = JSON.parse(formContext.getAttribute('data-bag-of-holding'))

			const isInitial = input.getAttribute('data-is-initial--limited')
			input.removeAttribute('data-is-initial--limited')

			// if value was empty and is now 'new' (or vice versa) OR value has not changed
			// update previous value and check bag status (latter is applicable for clones)
			const prevValue = input.getAttribute('data-prev-value--limited') || ''
			if (!!prevValue && input.value === 'new' || prevValue === 'new' && !input.value || !isInitial && prevValue == input.value) {
				input.setAttribute('data-prev-value--limited', input.value)
				Array.prototype.forEach.call(input.options, option => {
					if (!option.value || !bagOfValues[option.value]) return
					if (bagOfValues[option.value].taken >= bagOfValues[option.value].limit) {
						this.updateProp(option, 'disabled', 'limited', true)
					}
				})
				if (!input.value) {
					const label = input.closest('label')
					for (const property in bagOfValues) {
						bagOfValues[property].triggers.forEach(trigger => {
							if (trigger.class) {
								label.classList.remove(trigger.class)
								label.closest('.js-form-row').classList.remove(trigger.class + '--parent')
							}
							if (trigger.label) {
								input.querySelectorAll('option').forEach(option => {
									option.innerText = option.innerText.replace(new RegExp(trigger.label + '$'), '')
								})
							}
						})
					}
				}
				return
			}

			if (!isInitial && prevValue && bagOfValues[prevValue]) {
				bagOfValues[prevValue].taken -= 1
				formContext.querySelectorAll(['[name="' + input.name + '"] [value="' + prevValue + '"]']).forEach(option => {
					this.updateProp(option, 'disabled', 'limited', false)
				})
			}

			if (input.value && bagOfValues[input.value]) {
				bagOfValues[input.value].taken += 1
				if (bagOfValues[input.value].taken >= bagOfValues[input.value].limit) {
					formContext.querySelectorAll(['[name="' + input.name + '"] [value="' + input.value + '"]:not(:checked)']).forEach(option => {
						this.updateProp(option, 'disabled', 'limited', true)
					})
				}
				const label = input.closest('label')
				bagOfValues[input.value].triggers.forEach(trigger => {
					if (trigger.class) {
						label.classList.toggle(trigger.class, bagOfValues[input.value].taken >= trigger.taken)
						label.closest('.js-form-row').classList.toggle(trigger.class + '--parent', bagOfValues[input.value].taken >= trigger.taken)
					}
					if (trigger.label) {
						input.querySelectorAll('option').forEach(option => {
							option.innerText = option.innerText.replace(new RegExp(trigger.label + '$'), '')
						})
						if (bagOfValues[input.value].taken >= trigger.taken) {
							input.options[input.selectedIndex].innerText += trigger.label
						}
					}
				})
			}

			formContext.setAttribute('data-bag-of-holding', JSON.stringify(bagOfValues))
			input.setAttribute('data-prev-value--limited', input.value)
		})
	}

	initFormFieldIfRules () {

		// enforce if-rules
		document.addEventListener('change', event => {

			const input = event.target
			if (!input.classList.contains('js-form-field-if')) return

			const inputValue = input.type === 'radio' && !input.checked ? '' : input.value

			const prevValue = input.getAttribute('data-prev-value--if') || ''
			if (!prevValue && inputValue === 'new' || prevValue === 'new' && !inputValue || prevValue == inputValue) {
				return
			}

			const formContext = input.closest('.js-form-context--if') || input.closest('.js-form-context') || document
			formContext.querySelectorAll('[data-if--' + input.name + ']').forEach(ifField => {
				const ifValues = JSON.parse(ifField.getAttribute('data-if--' + input.name))
				const ifMatch = ifValues.includes(parseInt(inputValue))
				const ifProp = input.getAttribute('data-if-prop')
				this.updateProp(ifField, ifProp, 'if', !ifMatch)
				if (ifProp === 'disabled' && ifField.nodeName === 'OPTION') {
					ifField.selected = ifField.disabled ? false : ifField.selected
					ifField.parentNode.value = ifField.parentNode.value == ifField.value && ifField.disabled ? '' : ifField.parentNode.value
				}
			})

			input.setAttribute('data-prev-value--if', inputValue)
			this.triggerFieldChangeListeners()
		})

		// make sure that changing radio options triggers if-rules
		document.addEventListener('change', event => {

			const input = event.target
			if (input.type !== 'radio' || input.classList.contains('js-form-field-if')) return

			const ifInput = document.querySelector('[name="' + input.name + '"].js-form-field-if')
			if (ifInput) {
				ifInput.dispatchEvent(new Event('change', {
					'bubbles': true,
				}))
			}
		})
	}

	initFormFieldOnSelect () {

		// set values on select
		document.addEventListener('change', event => {
			if (!event.target.classList.contains('js-form-field-on-select')) return
			const option = event.target.options[event.target.selectedIndex]
			if (option) {
				const context = event.target.closest('.js-form-context--on-select') || event.target.closest('.js-form-context') || document
				const onSelect = JSON.parse(option.getAttribute('data-on-select'))
				for (let onSelectRow of onSelect) {
					context.querySelector(onSelectRow[0]).setAttribute(onSelectRow[1], onSelectRow[2])
				}
			}
		})
	}

	initFormFieldUnique () {

		// enforce unique fields (within form context)
		document.addEventListener('change', event => {
			if (!event.target.classList.contains('js-form-field-unique')) return
			const formContext = event.target.closest('.js-form-context--unique') || event.target.closest('.js-form-context') || document
			formContext.querySelectorAll('select[name="' + event.target.name + '"]').forEach(input => {
				input.querySelectorAll('option').forEach(option => {
					if (!option.value || option.value === 'new' || option.selected) return
					const existingOption = formContext.querySelector('select[name="' + input.name + '"] option[value="' + option.value + '"]:checked')
					this.updateProp(option, 'disabled', 'unique', !!existingOption)
				})
			})
		})
	}

	initFormFieldAnswered () {

		// mark fields answered
		document.addEventListener('change', event => {
			if (!event.target.classList.contains('js-mark-form-field-answered')) return
			event.preventDefault()
			if (event.target.value) {
				event.target.closest('label').classList.add('js-form-field--answered')
				return
			}
			event.target.closest('label').classList.remove('js-form-field--answered')
		})
	}

	initCloneNode () {

		// clone node buttons
		document.addEventListener('click', event => {
			const button = event.target.closest('.js-clone-node-button')
			if (!button || button.disabled) return
			event.preventDefault()
			const formContext = button.closest('.js-form-context--clone') || button.closest('.js-form-context') || document
			const sourceNode = document.querySelector(button.getAttribute('data-node-selector'))
			if (sourceNode) {
				const cloneNode = sourceNode.cloneNode(true)
				if (button.getAttribute('data-node-insert-after')) {
					const previousNode = document.querySelector(button.getAttribute('data-node-insert-after'))
					previousNode.insertAdjacentElement('afterend', cloneNode)
				} else {
					sourceNode.insertAdjacentElement('afterend', cloneNode)
				}
				cloneNode.setAttribute('data-is-clone', true)
				cloneNode.querySelectorAll('[data-remove-add-clone]').forEach(removeButton => {
					removeButton.removeAttribute('data-remove-add-clone')
				})
				cloneNode.classList.remove('js-hide')
				cloneNode.classList.remove('js-quick-add-context--active')
				const cloneNodeRevert = cloneNode.querySelectorAll('.js-quick-add-hide, .js-quick-add-show')
				if (cloneNodeRevert.length) {
					cloneNodeRevert.forEach(item => {
						if (item.classList.contains('js-quick-add-show')) {
							item.classList.add('js-hide')
						} else if (item.classList.contains('js-quick-add-hide')) {
							item.classList.remove('js-hide')
						}
					})
				}
				const cloneNodeInputs = cloneNode.querySelectorAll('select, input')
				if (cloneNodeInputs.length) {
					cloneNodeInputs.forEach(input => {
						if (input.type === 'hidden') return
						if (input.nodeName !== 'SELECT' || !input.required || input.querySelectorAll('option').length > 1) {
							input.value = ''
							input.removeAttribute('data-prev-value--limited')
							input.removeAttribute('data-prev-value--if')
							input.removeAttribute('data-prev-value--populate')
						}
						input.dispatchEvent(new Event('change', {
							'bubbles': true,
						}))
					})
					sourceNode.querySelectorAll('.js-form-field-unique').forEach(input => {
						input.dispatchEvent(new Event('change', {
							'bubbles': true,
						}))
					})
				}
				this.triggerFieldChangeListeners()
			}
			const cloneLimit = button.getAttribute('data-clone-max-items')
			if (cloneLimit && formContext.querySelectorAll(button.getAttribute('data-clone-selector')).length > cloneLimit) {
				button.classList.add('js-hide')
				return
			}
		})
	}

	initDeleteNode () {

		// delete node buttons
		document.addEventListener('click', event => {
			const button = event.target.closest('.js-remove-node-button')
			if (!button || button.disabled) return
			event.preventDefault()
			const targetNode = button.closest(button.getAttribute('data-node-selector'))
			if (targetNode) {
				if (targetNode.getAttribute('data-is-clone')) {
					const cloneContext = button.closest('.js-form-context--clone') || button.closest('.js-form-context') || document
					cloneContext.querySelectorAll('.js-clone-node-button').forEach(cloneButton => {
						cloneButton.classList.remove('js-hide')
					})
				}
				targetNode.querySelectorAll('select').forEach(input => {
					input.value = ''
					input.dispatchEvent(new Event('change', {
						'bubbles': true,
					}))
				})
				if (button.hasAttribute('data-remove-add-clone')) {
					const cloneButton = document.getElementById(button.getAttribute('data-remove-add-clone'))
					if (cloneButton) {
						cloneButton.setAttribute('data-clone-max-items', parseInt(cloneButton.getAttribute('data-clone-max-items')) + 1)
						if (cloneButton.classList.contains('js-hide')) {
							cloneButton.classList.remove('js-hide')
						}
					}
					// this is a once-per-element thing; re-adding will create a "data-is-clone" item
					button.removeAttribute('data-remove-add-clone')
				}
				targetNode.remove()
				this.triggerFieldChangeListeners()
			}
		})
	}

	initPopulateForm () {

		// populate form data from input
		document.addEventListener('change', event => {
			const input = event.target.closest('.js-populate-form')
			if (!input) return
			const prevValue = input.getAttribute('data-prev-value--populate') || ''
			if (prevValue === input.value) return
			input.setAttribute('data-prev-value--populate', input.value)
			const formContext = input.closest('.js-form-context--populate') || input.closest('.js-form-context') || document
			let formData = (input.nodeName === 'SELECT' ? input.options[input.selectedIndex] : input).getAttribute('data-form-data')
			if (formData) {
				formContext.querySelectorAll('[name]').forEach(formField => {
					if (formField.type === 'hidden' || formField === input) return
					formField.value = ''
				})
				formData = JSON.parse(formData)
				for (let fieldName in formData) {
					if (Object.prototype.hasOwnProperty.call(formData, fieldName) && formData[fieldName]) {
						const formField = formContext.querySelector('[name="' + fieldName + '"]')
						if (formField) {
							formField.value = formData[fieldName]
						}
					}
				}
			}
		})
	}

	initFormBuilderForm () {

		this.prepareFormBuilderForms()

		document.addEventListener('form-builder-submit', event => {
			const aggregateForm = document.getElementById('js-aggregate-form')
			if (aggregateForm) {
				if (event.detail.success && event.detail.form && event.detail.entry) {
					document.querySelector('.js-form-builder-form > [name="form"]').value = event.detail.form
					document.querySelector('.js-form-builder-form > [name="entry"]').value = event.detail.entry
					aggregateForm.classList.add('js-form-submit-anyway')
					const formRow = event.target.closest('.js-form-row')
					if (formRow) {
						formRow.querySelectorAll('.boxed-input').forEach(boxedInput => {
							boxedInput.classList.add('boxed-input--disabled')
						})
					}
					window.setTimeout(() => {
						aggregateForm.dispatchEvent(new Event('submit', {
							'bubbles': true,
						}))
					}, 4500)
				} else {
					this.prepareFormBuilderForms()
					document.querySelector('.js-form-builder-form form').setAttribute('tabindex', '-1')
					window._site.scrollTo(document.querySelector('.js-form-builder-form form'), true)
					aggregateForm.classList.remove('js-form-loading')
				}
			}
		})
	}

	prepareFormBuilderForms () {
		document.querySelectorAll('.js-form-builder-form .InputfieldSubmit').forEach(submit => {
			submit.remove()
		})
		document.querySelectorAll('.js-form-builder-form:not([data-is-changed])').forEach(formContainer => {
			formContainer._isChangedListener = () => {
				formContainer.setAttribute('data-is-changed', '')
				formContainer.removeEventListener('change', formContainer._isChangedListener)
				formContainer.removeEventListener('keyup', formContainer._isChangedListener)
			}
			formContainer.addEventListener('change', formContainer._isChangedListener)
			formContainer.addEventListener('keyup', formContainer._isChangedListener)
		})
	}

	initStandAloneFormBuilderForms () {

		const standAloneFormBuilderForms = document.querySelectorAll('.js-stand-alone-form-builder-form')
		if (!standAloneFormBuilderForms.length) return

		standAloneFormBuilderForms.forEach(formBuilderForm => {
			formBuilderForm.querySelector('[type="submit"]').addEventListener('click', event => {
				event.preventDefault()
				event.target.classList.add('button--ajax-loading')
				const form = formBuilderForm.querySelector('form')
				form.classList.add('js-form-in-progress')
				this.submitFormBuilderForm(form)
			})
		})

		document.addEventListener('form-builder-submit', event => {
			const formContainer = event.target.closest('.js-stand-alone-form-builder-form')
			if (event.detail.success && event.detail.form && event.detail.entry) {
				const valueInput = formContainer.hasAttribute('data-value-input-name')
					? formContainer.querySelector('[name="' + formContainer.getAttribute('data-value-input-name') + '"]')
					: null
				if (valueInput) {
					valueInput.value = 1
					if (formContainer.getAttribute('data-value-id')) {
						document.querySelectorAll('[data-value-for="' + formContainer.getAttribute('data-value-id') + '"]').forEach(valueContainer => {
							if (valueContainer.getAttribute('data-value') != valueInput.value) {
								valueContainer.classList.add('hidden')
							} else {
								valueContainer.classList.remove('hidden')
							}
						})
					}
				}
				document.querySelectorAll('[aria-controls="' + formContainer.id + '"]').forEach(formToggle => {
					formToggle.remove()
				})
			}
		})
	}

	initAggregateForm () {

		const aggregateForm = document.getElementById('js-aggregate-form')
		if (aggregateForm) {

			this.aggregateFormData = JSON.stringify(this.processAggregateForm(aggregateForm))

			aggregateForm._beforeunload = event => {
				const aggregateFormDataNow = JSON.stringify(this.processAggregateForm(aggregateForm))
				if (aggregateFormDataNow != this.aggregateFormData) {
					// const aggregateFormSubmit = aggregateForm.querySelector('[type="submit"]')
					// if (aggregateFormSubmit) {
					// 	aggregateFormSubmit.focus()
					// 	aggregateFormSubmit.classList.add('js-form-focus')
					// 	window.setTimeout(() => aggregateFormSubmit.classList.remove('js-form-focus-only'), 500)
					// }
					event.preventDefault()
					event.returnValue = 'Haluatko varmasti poistua tältä sivulta? Tekemäsi muutokset eivät tallennu.'
				}
			}

			window.addEventListener('beforeunload', aggregateForm._beforeunload)

			aggregateForm.addEventListener('submit', event => {

				event.preventDefault()

				// prevent double submissions, unless specifically allowed via .js-form-submit-anyway
				if (aggregateForm.classList.contains('js-form-loading') && !aggregateForm.classList.contains('js-form-submit-anyway')) {
					return
				}

				aggregateForm.classList.add('js-form-loading')
				aggregateForm.classList.remove('js-form-submit-anyway')

				const formContext = event.target.closest('.js-form-context--aggregate') || event.target.closest('.js-form-context') || document
				let formData = {}

				// look for Form Builder forms within, and validate or submit them first
				const formBuilderForm = formContext.querySelector('.js-form-builder-form')
				if (formBuilderForm) {
					if (formBuilderForm.hidden || formBuilderForm.hasAttribute('data-is-submitted') && !formBuilderForm.hasAttribute('data-is-changed')) {
						if (formBuilderForm.hidden) {
							formBuilderForm.querySelectorAll('[name="form"], [name="entry"]').forEach(input => {
								input.value = ''
							})
						}
						const form = formBuilderForm.querySelector('form.FormBuilder')
						if (form) {
							form.disabled = true
							form.querySelectorAll('input, textarea, select, button').forEach(input => {
								input.disabled = true
							})
						}
					} else {
						const formID = formBuilderForm.querySelector('[name="form"]').value
						const entryID = formBuilderForm.querySelector('[name="entry"]').value
						if (formBuilderForm.hasAttribute('data-is-changed') || !formID || !entryID) {
							formBuilderForm.removeAttribute('data-is-changed')
							this.submitFormBuilderForm(formBuilderForm.querySelector('form'))
							return
						}
					}
				}

				// prevent submitting aggregate form if there are active "quick add" forms within
				const activeQuickAdd = formContext.querySelector('.js-quick-add-context--active')
				if (activeQuickAdd) {
					let hasRequiredInput = false
					const requiredInputs = activeQuickAdd.querySelectorAll('.js-quick-add-input[required]')
					requiredInputs.forEach(requiredInput => {
						if (!hasRequiredInput && !requiredInput.value) {
							requiredInput.focus()
							requiredInput.reportValidity()
							hasRequiredInput = true
						}
					})
					if (!hasRequiredInput) {
						Swal.fire({
							html: '<div class="swal2-html-inner">'
								+ '<p>' + activeQuickAdd.getAttribute('data-quick-add-unsaved') + '</p>'
								+ '</div>',
							confirmButtonColor: '#FCE5EF',
							didClose: () => {
								const quickAddSave = activeQuickAdd.querySelector('.js-quick-add-save')
								quickAddSave.focus()
								quickAddSave.classList.add('js-form-focus')
								window.setTimeout(() => quickAddSave.classList.remove('js-form-focus'), 500)
							},
						})
					}
					aggregateForm.classList.remove('js-form-loading')
					return
				}

				formData = this.processAggregateForm(aggregateForm, true, true, true)
			})
		}
	}

	/**
	 * Process aggregate form
	 *
	 * This method returns form data, but also optionally reports errors, populates form, and submits form.
	 *
	 * @param {Object} aggregateForm
	 * @param {Boolean} reportErrors
	 * @param {Boolean} populateForm
	 * @param {Boolean} submitForm
	 * @return {Object}
	 */
	processAggregateForm (aggregateForm, reportErrors = false, populateForm = false, submitForm = false) {

		const formContext = aggregateForm.closest('.js-form-context--aggregate') || aggregateForm.closest('.js-form-context') || document
		const formData = {}

		const formRows = formContext.querySelectorAll('.js-form-row:not(.js-hide)')
		if (!formRows.length) {
			if (submitForm) {
				window.removeEventListener('beforeunload', aggregateForm._beforeunload)
				aggregateForm.submit()
			} else {
				aggregateForm.classList.remove('js-form-loading')
			}
			return {}
		}

		// reset errors
		let isError = false
		formContext.querySelectorAll('.js-form-error').forEach(formError => {
			formError.remove()
		})

		formRows.forEach(formRow => {
			if (isError) return
			const formDataRow = {}
			const formDataKey = formRow.getAttribute('data-form-data-key') || 'rows'
			const formFields = formRow.querySelectorAll('input, select, textarea')
			if (formFields.length) {
				formFields.forEach(formField => {
					if (isError) return
					if (formField.closest('.js-form-stop')) return
					if (formField.closest('.js-hide')) return
					if ((formField.type === 'checkbox' || formField.type === 'radio') && !formField.required && !formField.checked) {
						return
					}
					if (formField.type === 'radio' && !formField.checked && formRow.querySelector('[type="radio"][name="' + formField.name + '"]:checked')) {
						return
					}
					if (formField.checkValidity()) {
						formDataRow[formField.name] = formField.value
					} else {
						if (reportErrors) {
							if (formField.hidden) {
								const formError = document.createElement('div')
								formError.classList.add('js-form-error')
								formError.innerText = formField.validationMessage
								const previousError = formField.nextElementSibling.querySelector('.js-form-error')
								if (previousError) {
									previousError.remove()
								}
								let formErrorContainer = null
								if (formRow.getAttribute('data-form-error-container')) {
									formErrorContainer = document.getElementById(formRow.getAttribute('data-form-error-container'))
								}
								if (!formErrorContainer) {
									formErrorContainer = formField.nextElementSibling.querySelector('.js-form-error-container') || formField.nextElementSibling
								}
								formErrorContainer.insertAdjacentElement('afterbegin', formError)
								formError.setAttribute('tabindex', -1)
								formError.focus()
							} else {
								formField.focus()
								formField.reportValidity()
							}
						}
						isError = true
					}
				})
				if (!isError) {
					if (typeof formData[formDataKey] === 'undefined') {
						formData[formDataKey] = []
					}
					formData[formDataKey].push(formDataRow)
				}
			}
		})

		if (populateForm) {
			aggregateForm.querySelector('[name="data"]').value = JSON.stringify(formData)
		}

		if (submitForm && !isError) {
			window.removeEventListener('beforeunload', aggregateForm._beforeunload)
			aggregateForm.submit()
			return formData
		}

		aggregateForm.classList.remove('js-form-loading')
		return formData
	}

	/**
	 * Hide form open buttons
	 */
	hideFormOpenButtons () {
		const formButtons = document.querySelectorAll('.js-form-button:not(.js-form-close)')
		if (formButtons.length) {
			formButtons.forEach(formButton => {
				if (formButton.classList.contains('js-show')) {
					formButton.setAttribute('data-revert-class', 'js-show')
					formButton.classList.remove('js-show')
				} else if (formButton.classList.contains('js-flex')) {
					formButton.setAttribute('data-revert-class', 'js-flex')
					formButton.classList.remove('js-flex')
				}
				formButton.classList.add('js-hide')
				formButton.setAttribute('data-remove-class', 'js-hide')
			})
		}
	}

	/**
	 * Restore previously hidden form buttons
	 */
	restoreFormButtons () {
		const formButtons = document.querySelectorAll('[data-revert-class], [data-remove-class]')
		if (formButtons.length) {
			formButtons.forEach(formButton => {
				const removeClass = formButton.getAttribute('data-remove-class')
				if (removeClass) {
					formButton.classList.remove(removeClass)
					formButton.removeAttribute('data-remove-class')
				}
				const revertClass = formButton.getAttribute('data-revert-class')
				if (revertClass) {
					formButton.classList.add(revertClass)
					formButton.removeAttribute('data-revert-class')
				}
			})
		}
	}

	/**
	 * Confirm submit for forms and buttons
	 *
	 * @param {Event} event
	 */
	confirmSubmit (event) {
		const confirm = event.target.closest('.js-confirm')
		if (!confirm) return
		if (confirm.type === 'submit') {
			const form = confirm.closest('form')
			if (form && form.classList.contains('js-form-in-progress')) {
				event.preventDefault()
				return
			}
		}
		event.preventDefault()
		Swal.fire({
			title: confirm.getAttribute('data-confirm-title'),
			html: confirm.getAttribute('data-confirm-html'),
			confirmButtonText: confirm.getAttribute('data-confirm-button-text'),
			cancelButtonText: confirm.getAttribute('data-cancel-button-text'),
			denyButtonText: confirm.getAttribute('data-deny-button-text'),
			showDenyButton: confirm.hasAttribute('data-deny-button-text'),
			showCancelButton: !confirm.hasAttribute('data-deny-button-text'),
			color: '#0D0D0D',
			confirmButtonColor: confirm.hasAttribute('data-swal-reverse') ? '#F3F5E6' : '#FCE5EF',
			cancelButtonColor: confirm.hasAttribute('data-swal-reverse') ? '#FCE5EF' : '#F3F5E6',
			denyButtonColor: confirm.hasAttribute('data-swal-reverse') ? '#FCE5EF' : '#F3F5E6',
			customClass: {
				container: confirm.hasAttribute('data-swal-reverse') ? 'swal2-reverse' : '',
			},
		}).then(result => {
			if (result.isConfirmed || result.isDenied) {
				const form = confirm.closest('form')
				if (form) {
					const valueInput = confirm.hasAttribute('data-value-input-name')
						? form.querySelector('[name="' + confirm.getAttribute('data-value-input-name') + '"]')
						: null
					if (valueInput) {
						valueInput.value = 1
					}
					if (result.isDenied) {
						if (valueInput) {
							valueInput.value = 0
						} else {
							confirm.removeAttribute('disabled')
							if (confirm.type !== 'submit') {
								const button = confirm.querySelector('button[type="submit"][disabled]')
								if (button) {
									button.removeAttribute('disabled')
								}
							}
							return
						}
					}
					if (form.classList.contains('js-submit')) {
						const formData = new FormData(form)
						fetch(form.getAttribute('action'), {
							body: formData,
							method: 'post',
							headers: {
								'X-Requested-With': 'XMLHttpRequest',
							},
						})
							.then(response => response.json())
							.then(data => {
								if (data.error) {
									Swal.fire({
										html: '<div class="swal2-html-inner">'
											+ '<p>' + data.error + '</p>'
											+ '</div>',
										confirmButtonColor: '#FCE5EF',
									})
								} else {
									Swal.fire({
										html: '<div class="swal2-html-inner">'
											+ '<p>' + data.message + '</p>'
											+ '</div>',
										customClass: {
											confirmButton: 'swal2-confirm--success',
										},
										confirmButtonColor: '#E0E6C2',
									})
									if (form.getAttribute('data-submit-remove')) {
										document.querySelector(form.getAttribute('data-submit-remove')).remove()
									}
									if (valueInput && form.getAttribute('data-value-id')) {
										document.querySelectorAll('[data-value-for="' + form.getAttribute('data-value-id') + '"]').forEach(valueContainer => {
											if (valueContainer.getAttribute('data-value') != valueInput.value) {
												valueContainer.classList.add('hidden')
											} else {
												valueContainer.classList.remove('hidden')
											}
										})
									}
								}
							})
						confirm.removeAttribute('disabled')
						if (confirm.type !== 'submit') {
							const button = confirm.querySelector('button[type="submit"][disabled]')
							if (button) {
								button.removeAttribute('disabled')
							}
						}
					} else {
						form.submit()
					}
				}
			} else {
				confirm.removeAttribute('disabled')
				if (confirm.type !== 'submit') {
					const button = confirm.querySelector('button[type="submit"][disabled]')
					if (button) {
						button.removeAttribute('disabled')
					}
				}
			}
		})
	}

	/**
	 * Submit FormBuilder form
	 *
	 * @param {Object} form
	 */
	submitFormBuilderForm (form) {

		const formData = new FormData(form)
		formData.set(form.name + '_submit', 1)

		fetch('/form-builder/' + form.name + '/', {
			method: 'POST',
			body: formData,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		})
			.then(response => response.text())
			.then(data => {
				const formContainer = form.parentNode
				formContainer.innerHTML = data
				const formSuccess = formContainer.querySelector('.form__success')
				formContainer.dispatchEvent(new CustomEvent('form-builder-submit', {
					'bubbles': true,
					'detail': {
						'form': formSuccess ? formSuccess.getAttribute('data-form') : null,
						'entry': formSuccess ? formSuccess.getAttribute('data-entry') : null,
						'success': !!formSuccess,
					},
				}))
			})
	}

	/**
	 * Trigger field change listeners
	 *
	 * There are a number of features on the site that trigger on change effect. In some cases (e.g. when a form gets
	 * cloned) these need to be rerun.
	 */
	triggerFieldChangeListeners () {
		const inputs = document.querySelectorAll([
			'.js-form-field-unique',
			'.js-populate-form',
			'.js-mark-form-field-answered',
			'.js-form-field-limited',
			'.js-form-field-if',
		].join(', '))
		if (inputs.length) {
			inputs.forEach(input => {
				input.dispatchEvent(new Event('change', {
					'bubbles': true,
				}))
			})
		}
	}

	/**
	 * Update node property depending on (potentially) multiple stackable conditions
	 *
	 * @param {Object} node
	 * @param {String} prop
	 * @param {String} condition
	 * @param {Boolean} conditionValue
	 */
	updateProp (node, prop, condition, conditionValue) {
		node._prop_stack = node._prop_stack || {}
		node._prop_stack[prop] = node._prop_stack[prop] || {}
		if (conditionValue) {
			node._prop_stack[prop][condition] = conditionValue
			node[prop] = true
		} else {
			delete node._prop_stack[prop][condition]
			if (!node._prop_stack[prop] || !Object.keys(node._prop_stack[prop]).length) {
				node[prop] = false
			}
		}
	}
}
