'use strict'

/**
 * List Filters for searching/filtering content according to HTML content / attributes.
 *
 * @version 0.0.1
 */
export default class ListFilters {

	/**
	 * Class constructor
	 */
	constructor () {
		this.init()
	}

	/**
	 * Initialize list filters
	 */
	init () {

		const filters = document.querySelectorAll('[data-filter-list]')
		if (!filters.length) return

		const params = new URLSearchParams(window.location.search)
		let filtered = false
		let filteringTimeout = null
		let filteredTimeout = null
		let isFiltering = false

		filters.forEach(filter => {
			const list = document.getElementById(filter.getAttribute('data-filter-list'))
			if (!list) return

			const filterIndicatorDefault = document.querySelector('[data-filter-indicator-default="' + list.id + '"]')
			const filterIndicatorLoading = document.querySelector('[data-filter-indicator-loading="' + list.id + '"]')

			const form = filter.closest('form:not([data-filter-form]')
			if (form) {
				form.setAttribute('data-filter-form', filter.getAttribute('data-filter-list'))
				if (!list.getAttribute('tabindex')) {
					list.setAttribute('tabindex', '-1')
				}
				form.addEventListener('submit', e => {
					e.preventDefault()
					list.focus()
				})
			}

			const filterProp = filter.getAttribute('data-filter-prop') || 'innerText'
			const noResults = list.getAttribute('data-filter-no-results-id')
				? document.getElementById(list.getAttribute('data-filter-no-results-id'))
				: null

			filter.addEventListener(filter.nodeName === 'SELECT' || filter.type === 'checkbox' ? 'change' : 'keyup', () => {
				if (!isFiltering) {
					if (filterIndicatorDefault) filterIndicatorDefault.classList.add('hidden')
					if (filterIndicatorLoading) filterIndicatorLoading.classList.remove('hidden')
				}
				isFiltering = true
				window.clearTimeout(filteredTimeout)
				window.clearTimeout(filteringTimeout)
				this.filterListItems(filter, list, filterProp, noResults)
				filteringTimeout = window.setTimeout(() => {
					if (filterIndicatorLoading) filterIndicatorLoading.classList.add('hidden')
					if (filterIndicatorDefault) filterIndicatorDefault.classList.remove('hidden')
					if (form) this.scrollTo(form)
					isFiltering = false
				}, 250)
			})

			if (filter.name && params.has(filter.name)) {
				if (filter.type !== 'checkbox') {
					filter.value = params.get(filter.name)
				}
				this.filterListItems(filter, list, filterProp, noResults, true)
				filtered = list.classList.contains('hidden') && noResults ? noResults : list
			}
		})

		if (filtered && !isFiltering) {
			filteredTimeout = window.setTimeout(() => {
				this.scrollTo(filtered, true)
			}, 250)
		}

		document.addEventListener('click', event => {
			if (event.target.classList.contains('button--disabled')) return
			const triggerFilter = event.target.getAttribute('data-trigger-filter')
			if (triggerFilter) {
				const triggerFilterValue = event.target.getAttribute('data-trigger-filter-value')
				if (triggerFilterValue) {
					event.preventDefault()
					const filter = document.querySelector('[name="' + triggerFilter + '"][value="' + triggerFilterValue + '"]')
					if (filter) {
						if (filter.nodeName === 'SELECT') {
							filter.value = filter.value == triggerFilterValue ? null : triggerFilterValue
							event.target.classList.toggle('button--active', filter.value !== null)
							filter.dispatchEvent(new Event('change'))
						} else if (filter.type === 'checkbox') {
							filter.checked = filter.checked ? null : 'checked'
							event.target.classList.toggle('button--active', filter.checked)
							filter.dispatchEvent(new Event('change'))
						} else {
							filter.value = filter.value == triggerFilterValue ? '' : triggerFilterValue
							event.target.classList.toggle('button--active', filter.value !== '')
							filter.dispatchEvent(new Event('keyup'))
						}
						const filterContainer = event.target.closest('.js-filter-container')
						if (filterContainer) {
							filterContainer.setAttribute('tabindex', -1)
							window.setTimeout(() => {
								this.scrollTo(filterContainer, true)
							}, 50)
						}
					}
				}
			}
		})

		document.querySelectorAll('[data-trigger-filter]').forEach(trigger => {
			const triggerFilter = trigger.getAttribute('data-trigger-filter')
			if (triggerFilter) {
				const triggerFilterValue = trigger.getAttribute('data-trigger-filter-value')
				if (triggerFilterValue) {
					const filter = document.querySelector('[name="' + triggerFilter + '"][value="' + triggerFilterValue + '"]')
					if (filter) {
						if (filter.nodeName === 'SELECT') {
							trigger.classList.toggle('button--active', filter.value == triggerFilterValue)
						} else if (filter.type === 'checkbox') {
							trigger.classList.toggle('button--active', filter.checked)
						} else {
							trigger.classList.toggle('button--active', filter.value == triggerFilterValue)
						}
					}
				}
			}
		})
	}

	/**
	 * Filter list items
	 *
	 * @param {Element} filter
	 * @param {Element} list
	 * @param {String} filterProp
	 * @param {Element} noResults
	 * @param {Boolean} isInitial
	 */
	filterListItems (filter, list, filterProp, noResults, isInitial) {

		// visibility key
		const visibilityKey = filter.getAttribute('data-filter-vkey') || filterProp
		const filterValue = filter.type === 'checkbox' ? (filter.checked ? filter.value : '') : filter.value

		// filter items
		list.querySelectorAll(':scope > :not([data-filter-group-label])').forEach(item => {
			let itemCount = parseInt(item.getAttribute('data-filter-count') || 0)
			const itemValue = filterProp === 'innerText' ? item.innerText : item.getAttribute(filterProp)
			if (itemCount && item.classList.contains('hidden--' + visibilityKey)) {
				if (!filterValue.length || itemValue.toUpperCase().indexOf(filterValue.toUpperCase()) !== -1) {
					itemCount--
					item.classList.remove('hidden--' + visibilityKey)
					item.setAttribute('data-filter-count', itemCount)
					if (!itemCount) {
						item.classList.remove('hidden')
					}
				}
			} else if (filterValue.length && itemValue.toUpperCase().indexOf(filterValue.toUpperCase()) === -1) {
				itemCount++
				item.classList.add('hidden--' + visibilityKey)
				item.setAttribute('data-filter-count', itemCount)
				item.classList.add('hidden')
			}
			const group = item.getAttribute('data-filter-group')
			if (group) {
				const label = list.querySelector('[data-filter-group-label="' + group + '"]')
				if (label) {
					if (list.querySelector('[data-filter-group="' + group + '"]:not(.hidden)')) {
						label.classList.remove('hidden')
					} else {
						label.classList.add('hidden')
					}
				}
				const counters = list.querySelectorAll('[data-filter-group-counter="' + group + '"]')
				if (counters.length) {
					const visibleItemCount = list.querySelectorAll(':scope > :not([data-filter-group-label]):not(.hidden)').length
					counters.forEach(counter => {
						counter.innerText = visibleItemCount
					})
				}
			}
		})

		if (isInitial !== true) {
			// update URL (note: filter.value is required to support checkboxes)
			this.setQueryStringParam(filter.name, filterValue, filter.value)
		}

		// show or hide the no results element
		if (noResults) {
			noResults.classList.add('hidden')
			if (list.querySelector(':scope > :not([data-filter-group-label]):not(.hidden)')) {
				list.classList.remove('hidden')
				return
			}
			list.classList.add('hidden')
			noResults.classList.remove('hidden')
		}
	}

	/**
	 * Set or update query string parameter
	 *
	 * @param {String} name
	 * @param {String|int|float} value
	 * @param {String|int|float} inputValue
	 */
	setQueryStringParam (name, value, inputValue) {

		// construct URL search params object
		const params = new URLSearchParams(window.location.search)
		if (value === '' && name.endsWith('[]')) {
			const allValues = params.getAll(name).filter(singleValue => singleValue !== inputValue)
			if (allValues.length) {
				params.set(name, allValues)
			} else {
				params.delete(name)
			}
		} else if (value === '') {
			params.delete(name)
		} else if (name.endsWith('[]') && params.has(name)) {
			params.append(name, value)
		} else {
			params.set(name, value)
		}

		// construct query string
		let queryStr = ''
		let paramsIterated = []
		params.forEach((value, param) => {
			if (paramsIterated.includes(param)) return
			paramsIterated.push(param)
			if (param.endsWith('[]')) {
				value = params.getAll(param).join('&' + param + '=')
			}
			queryStr += (queryStr === '' ? '?' : '&') + param + '=' + value
		})

		// update URL
		window.history.replaceState({}, '', decodeURIComponent(window.location.pathname + queryStr))
	}
}
